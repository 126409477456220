<template>
	<div id="listTransfersfromCashierBank" class="input-heading">
		<h3>List Transfers at this Cashier Bank</h3>
		<div v-if="cashierBankTransfers != []">
			<div id="paging" v-if="cashierBankTransfers.length > 0">
				<label for="limitOptions">Limit List</label>
				<select v-model="transferLimit" id="limitOptions">
					<option v-for="(item, index) in limitOptions" :key="index" :value="item.value">{{ item.value }}</option>
				</select>
				<button class="prev-button" type="button" @click="previousPage($event)">previous</button>
				<button class="next-button" type="button" @click="nextPage($event)">next</button>
				<span :currentPage="currentPage">page {{ currentPage }}</span>
			</div>
			<div class="bank-lists-container">
				<div v-if="!getListComplete" id="bank-transfers-loading">
					<span class="loading-icon"></span>
					Loading List
				</div>
				<table v-if="cashierBankTransfers.length > 0" class="cashier-banks">
					<tr class="header-row">
						<th>Player Initials</th>
						<th>Date</th>
						<th>Regular</th>
						<th>Promo</th>
						<th>Cash</th>
					</tr>
					<tr class="data-row" v-for="(item, index) in cashierBankTransfers" :key="index">
						<td class="player-id">
							<span
								:style="
									item.playerUserId
										? `background-color: #${generateUserIdColor(item.playerUserId).background}; color: #${
												generateUserIdColor(item.playerUserId).color
										  }`
										: ''
								"
								>{{ item.playerInitials }}</span
							>
						</td>

						<td>{{ item.date }}</td>
						<td>{{ inGameCurrencyTool.formatFromAU(item.toPlayerAmount.regularAU, inGameMinorWholeOrFull) }}</td>
						<td>{{ inGameCurrencyTool.formatFromAU(item.toPlayerAmount.promoAU, inGameMinorWholeOrFull) }}</td>
						<td>{{ systemCurrencyTool.formatCurrency(item.fromPlayerCashCOC, systemWholeOrFull) }}</td>
					</tr>
				</table>

				<div v-if="!cashierBankTransfers.length > 0 && getListComplete">
					<h2>No Transfers Found</h2>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import sharedScripts from "@/dependencies/sharedScripts";
import router from "@/router/index";
import { onBeforeUnmount } from "vue";
export default {
	name: "ListCashierBankFills",
	props: {
		cashierState: {
			type: Object,
		},
		appDataBus: {
			type: Object,
		},
		inGameCurrencyTool: Object,
		systemCurrencyTool: Object,
		languageStrings: Object,
		languageErrorStrings: Object,
	},
	data() {
		return {
			activeSession: this.session.get(),
			status: Object.assign({}, this.globalStatus),
			toBankAmount: {},
			transferOffset: 0,
			transferLimit: 20,
			currentPage: 1,
			isLastPage: false,
			cashierBankTransfers: [],
			getListComplete: false,
			currencyInfo: this.cashierState.currencyInfo,
			systemWholeOrFull: this.systemCurrencyTool.displayType.wholeOrFull,
			inGameMinorWholeOrFull: this.inGameCurrencyTool.displayType.minorWholeOrFull,
		};
	},
	watch: {
		transferLimit() {
			this.currentPage = 1;
			this.transferOffset = 0;
			this.listTransfersfromCashierBank(this.transferLimit, this.transferOffset);
		},
	},
	methods: {
		async listTransfersfromCashierBank(transferLimit = null, transferOffset = null) {
			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverStatus.serverBusy = false;
				this.serverStatus.busyText = "";
				return false;
			}

			try {
				let limit = transferLimit;
				let offset = transferOffset;
				let STATE = this.cashierState;
				this.getListComplete = false;

				if (!limit) limit = this.transferLimit;

				if (!offset) offset = this.transferOffset;

				this.cashierBankId = this.cashierBankId || this.cashierState.cashierBankId;

				if (!this.cashierBankId) {
					let bankList = await sharedScripts.getBanksList(this);

					let bankListStatus = sharedScripts.checkFetchErrors(bankList, this.languageErrorStrings);

					if (bankListStatus && !bankListStatus.ok) {
						this.eventBus.emit("updateStatus", bankListStatus);
						return;
					}
					let openBank = bankList.find((element) => element.cashierUserId == this.activeSession.userId);
					this.cashierBankId = openBank.id;
				}

				let headerObj = new Headers();
				headerObj.append("Authorization", `Bearer ${this.cashierState.accessToken}`);
				headerObj.append("Content-Type", "application/json; charset=utf-8");
				let requestUrl = new URL(`api/v1/cashier/bank/${this.cashierBankId}/transfers`, this.rabbitsfootHostUrl);
				let params = requestUrl.searchParams;

				if (offset) params.set("offset", offset);

				// get one more item than requested to see if a second page exists
				if (limit) params.set("limit", Number(limit) + 1);

				requestUrl.search = params.toString();

				let request = new Request(requestUrl.toString(), {
					method: "GET",
					headers: headerObj,
				});

				const response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout === true) this.eventBus.emit("forceLogout");
					return false;
				}

				var dataJson = await response.json();

				this.isLastPage = dataJson?.length <= this.transferLimit;

				if (Array.isArray(dataJson)) {
					// remove extra item if a second page exists
					if (!this.isLastPage) dataJson.pop();
					dataJson.forEach((item) => {
						item.date = new Date(item.date).toLocaleTimeString([], this.dateOptions);
					});
				}

				this.getListComplete = true;

				this.cashierBankTransfers = dataJson;
			} catch (e) {
				console.error(e);
			}
		},
		async previousPage() {
			if (this.currentPage == 1) return;
			this.currentPage--;
			this.transferOffset = Number(this.transferOffset) - Number(this.transferLimit);
			this.listTransfersfromCashierBank(this.transferLimit, this.transferOffset);
		},
		async nextPage() {
			if (this.cashierBankTransfers.length < this.transferLimit) return;
			this.transferOffset = Number(this.transferOffset) + Number(this.transferLimit);
			this.currentPage++;
			this.listTransfersfromCashierBank(this.transferLimit, this.transferOffset);
		},
	},
	mounted() {
		this.listTransfersfromCashierBank();
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#listTransfersfromCashierBank {
	padding: 15px;
	height: calc(100vh - 20em);
	overflow: hidden;
}

#listTransfersfromCashierBank::-webkit-scrollbar-track {
	box-shadow: inset 1px 1px 6px rgb(0 0 0 / 75%);
	border-radius: 16px;
	background-color: #434250;
}

#listTransfersfromCashierBank::-webkit-scrollbar {
	width: 32px;
}

#listTransfersfromCashierBank::-webkit-scrollbar-thumb {
	border-radius: 16px;
	box-shadow: inset -2px -2px 6px rgb(0 0 0 / 75%);
	background-color: #bccfe5;
}

.bank-lists-container {
	height: calc(100vh - 30em);
	overflow: hidden auto;
}

.cashier-banks {
	width: 100%;
	text-align: center;
	position: relative;
}

.cashier-banks tr {
	transition: background-color 0.3s;
}

.cashier-banks tr:nth-child(2n) {
	background-color: #414650;
}

.cashier-banks tr.data-row:hover {
	background-color: #dfdfdf;
	color: #000;
}

.debug {
	display: none;
}

#bank-transfers-loading {
	text-align: center;
	display: grid;
	position: absolute;
	margin: auto;
	width: 100%;
	z-index: 200;
	background-color: rgb(0 0 0 / 80%);
	padding: 60px;
}

.header-row {
	z-index: 1;
}
</style>
